var _a;
const sonosCookiePrefix = (_a = process.env.NEXT_PUBLIC_SONOS_COOKIE_PREFIX) != null ? _a : "";
const slasCookie = `${sonosCookiePrefix}slasToken`;
const bridgedCookie = `${sonosCookiePrefix}bridged`;
const idpCookie = `${sonosCookiePrefix}idpToken`;
const loggedInCookie = `${sonosCookiePrefix}loggedIn`;
const linkedCookie = `${sonosCookiePrefix}linked`;
const refreshCookie = `${sonosCookiePrefix}refreshToken`;
const bearerCookie = `${sonosCookiePrefix}bearerToken`;
const fhCartCookie = `${sonosCookiePrefix}fhCart`;
const srcPromoCookie = `${sonosCookiePrefix}srcPromoCookie`;
const cartCookie = "cart";
const geolocalizationCookie = "geolocalization";
const paramsToRetainCookie = `${sonosCookiePrefix}paramsToRetain`;
const sonosId1Cookie = "sonosid-1";
const sonosId2Cookie = "sonosid-2";
const pendingEmailVerificationCookie = "pendingEmailVerification";
const preLoginQueryCookie = `${sonosCookiePrefix}preLoginQuery`;
const clientOktaBearerCookie = `${sonosCookiePrefix}idpToken`;
const myAccountLoggedInCookie = `${sonosCookiePrefix}loggedIn`;
const sonosOptimizelyUserId = `${sonosCookiePrefix}sonosOptimizelyId`;

export { bearerCookie, bridgedCookie, cartCookie, clientOktaBearerCookie, fhCartCookie, geolocalizationCookie, idpCookie, linkedCookie, loggedInCookie, myAccountLoggedInCookie, paramsToRetainCookie, pendingEmailVerificationCookie, preLoginQueryCookie, refreshCookie, slasCookie, sonosId1Cookie, sonosId2Cookie, sonosOptimizelyUserId, srcPromoCookie };
