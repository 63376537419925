import { numericRegex, isEmpty } from '@sonos-inc/www-lib';

const mapEntries = (obj, map) => Object.fromEntries(map(Object.entries(obj || {})));
const mapKeys = (obj, map) => mapEntries(obj, (entries) => entries.map(([name, value]) => [map(name, value), value]));
const mapValues = (obj, map) => mapEntries(obj, (entries) => entries.map(([name, value]) => [name, map(name, value)]));
const dropArrayEmptyValues = (array) => {
  if (!Array.isArray(array))
    return [];
  return array.filter((element) => !!element);
};
const dropNumericKeyEntries = (obj) => mapEntries(obj, (entries) => entries.filter(([name]) => !name.match(numericRegex)));
const dropEmptyValueEntries = (obj) => mapEntries(obj, (entries) => entries.filter(([_, value]) => !isEmpty(value)));
const mapStringKeyValues = (obj, map) => mapValues(dropNumericKeyEntries(obj), map);

export { dropArrayEmptyValues, dropEmptyValueEntries, dropNumericKeyEntries, mapEntries, mapKeys, mapStringKeyValues, mapValues };
