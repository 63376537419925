import '@sonos-inc/www-bakery/globals'

import { Query } from '@sonos-inc/web-lib-appsync'
import { SanityWwwLocale } from '@sonos-inc/web-lib-types'
import { bake } from '@sonos-inc/www-bakery'
import {
  ConditionalFragment,
  Footer,
  Footnotes,
  gtmPageView,
  Header,
  mainContainer,
  Parachute,
  SplashPage,
  withOptimized,
} from '@sonos-inc/www-components'
import {
  CartProvider,
  ContentProvider,
  ExtendedWarrantyProvider,
  FootnotesProvider,
  GtmProvider,
  initExtend,
  LocaleProvider,
  OverlaysProvider,
  PreviewProvider,
  RootProvider,
  SonosOptimizelyProvider,
  SvgsProvider,
} from '@sonos-inc/www-contexts'
import { Cookie, useNextCookie as useCookie } from '@sonos-inc/www-hooks'
import { paramsToRetainCookie } from '@sonos-inc/www-server/constants'
import { client } from '@sonos-inc/www-server/fetch/gql.browser'
import { SanityPageData } from '@sonos-inc/www-server/fetch/sanity.server'
import { Svg } from '@sonos-inc/www-types'
import { LazyMotion } from 'framer-motion'
import { setup } from 'goober'
import { shouldForwardProp } from 'goober/should-forward-prop'
import { ClientContext } from 'graphql-hooks'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import Script from 'next/script'
import React from 'react'

setup(
  React.createElement,
  undefined,
  undefined,
  shouldForwardProp((prop) => {
    // Do NOT forward props that start with `$` symbol
    return prop[0] !== '$'
  })
)

const domMaxFeature = () => {
  return import('framer-motion').then((mod) => mod.domMax)
}

interface AppProps extends SanityPageData {
  shouldOverlayHeader: boolean
  suppressFooter: boolean
  previewDate: string | null
  navHeaderSubCategoryData?: Query
  splashPage?: any
  footnotesJumpLinkEnabled?: boolean
}

// Order is matter!
const buildProviders = ({
  microcopy,
  forms,
  dtcValueProps,
  currentLocale,
  svgs,
  previewDate,
  footnotesJumpLinkEnabled,
}: AppProps) => [
  <SonosOptimizelyProvider key={'www-optimizely-home'} />,
  <GtmProvider key={'gtm-provider'} />,
  <ClientContext.Provider key={'client-context-provider'} value={client} />,
  <CartProvider key={'cart-provider'} />,
  <ContentProvider
    key={'content-provider'}
    microcopy={microcopy}
    forms={forms}
    dtcValueProps={dtcValueProps}
  />,
  <LocaleProvider key={'locale-provider'} currentLocale={currentLocale} />,
  <SvgsProvider key={'svgs-provider'} svgs={svgs as Svg[]} />,
  <PreviewProvider key={'preview-provider'} previewDate={previewDate} />,
  <OverlaysProvider key={'overlays-provider'} />,
  <FootnotesProvider
    key={'footnotes-provider'}
    jumpLinkEnabled={footnotesJumpLinkEnabled}
  />,
  <LazyMotion key="lazy-motion-provider" features={domMaxFeature} />,
  <ExtendedWarrantyProvider key={'extended-warranty-provider'} />,
]

export const App = withOptimized(function App({
  Component: PageContent,
  pageProps,
}: {
  Component: NextPage
  pageProps: AppProps
}) {
  const {
    header,
    footer,
    ribbon,
    shouldOverlayHeader,
    suppressFooter,
    navHeaderSubCategoryData,
    stickySubnav,
    splashPage,
    currentLocale,
  } = pageProps

  const [splashBypass] = useCookie('splashBypass')
  // can change to icing.chocolate for dark mode :)
  const router = useRouter()

  React.useEffect(() => {
    initExtend(currentLocale)
  }, [currentLocale])

  React.useEffect(() => {
    const handleRouteChange = (url: string) => gtmPageView(url)
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  React.useEffect(() => {
    if (window.location.search) {
      let existingCookie
      try {
        existingCookie = Cookie.getCookie(paramsToRetainCookie)
          ? JSON.parse(Cookie.getCookie(paramsToRetainCookie) as string)
          : {}
      } catch (e) {
        existingCookie = {}
      }
      Cookie.setCookie(
        paramsToRetainCookie,
        JSON.stringify({ ...router.query, ...existingCookie })
      )
    }
  }, [router.query])

  const headerVariant = shouldOverlayHeader ? 'overlayBase' : 'default'
  const splashPageActive = React.useMemo(() => {
    return !!splashPage && splashBypass !== process.env.SPLASH_PAGE_BYPASS
  }, [splashPage, splashBypass])

  return (
    <RootProvider providers={buildProviders(pageProps)}>
      <ConditionalFragment shouldRender={!!header}>
        <Header
          key={`header-${router.asPath}`}
          variant={headerVariant}
          ribbon={ribbon}
          subCategoryData={navHeaderSubCategoryData}
          splashPageActive={splashPageActive}
          stickySubnav={stickySubnav}
          {...header!}
        />
      </ConditionalFragment>
      <main
        key={`body-${router.asPath}`}
        id="content"
        {...bake(mainContainer)}
        tabIndex={-1}
      >
        {splashPageActive ? (
          <SplashPage {...splashPage} />
        ) : (
          <PageContent key={router.asPath} {...(pageProps as any)} />
        )}
      </main>
      <Footnotes />
      <ConditionalFragment shouldRender={!(suppressFooter || splashPageActive)}>
        <Parachute>
          <ConditionalFragment shouldRender={!!footer}>
            <Footer key={`footer-${router.asPath}`} footer={footer} />
          </ConditionalFragment>
        </Parachute>
      </ConditionalFragment>

      <ConditionalFragment
        shouldRender={
          (currentLocale as SanityWwwLocale | null | undefined)
            ?.extendFeatureEnabled
        }
      >
        <Script
          id={'extend-sdk'}
          strategy={'beforeInteractive'}
          src={process.env.NEXT_PUBLIC_EXTEND_SDK_URL}
        />
      </ConditionalFragment>
    </RootProvider>
  )
})

export default App
