import { palette } from './icing/recipe.css.vanilla.mjs';

function getForegroundColorFromBackgroundColor(colorKey) {
  const mediumLightPattern = /(Medium|Light)$/;
  if (mediumLightPattern.test(colorKey)) {
    return palette[colorKey.replace(mediumLightPattern, "Dark")];
  } else if (colorKey === "main" || colorKey.endsWith("Darkest")) {
    return palette.base;
  } else if (colorKey.endsWith("Dark")) {
    return palette[colorKey.replace("Dark", "Light")];
  } else if (colorKey.includes("clear")) {
    return palette[colorKey.replace("clear", "").toLowerCase()];
  }
  return palette.main;
}

export { getForegroundColorFromBackgroundColor };
