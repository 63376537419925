'use strict';

var blockContentToReact = require('@sanity/block-content-to-react');

var r=(o=>(o.Hidden="hidden",o.Visible="visible",o))(r||{});

exports.AnimatedStatesEnum = r;
Object.keys(blockContentToReact).forEach(function (k) {
	if (k !== 'default' && !Object.prototype.hasOwnProperty.call(exports, k)) Object.defineProperty(exports, k, {
		enumerable: true,
		get: function () { return blockContentToReact[k]; }
	});
});
