import { grids } from './dividers.mjs';

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const scales = __spreadProps(__spreadValues({
  autoSize: {
    auto: "auto",
    fitContent: "fit-content"
  }
}, grids), {
  fontSize: {
    caption: "10px",
    ts0p5: "12px",
    ts1: "14px",
    bodyCopy: "16px",
    ts1p5: "18px",
    ts2: "21px",
    ts2p5: "26px",
    ts3: "32px",
    ts3p5: "40px",
    ts4: "48px",
    ts4p5: "54px",
    ts4p7: "64px",
    ts5: "72px",
    ts5p5: "96px",
    ts6: "108px",
    ts7: "162px",
    ts8: "243px"
  },
  fontWeight: {
    light: "300",
    regular: "400",
    medium: "500",
    bold: "700"
  },
  lineHeight: {
    ls0: "1",
    ls1: "1.2",
    ls1p5: "1.3",
    ls2: "1.4",
    ls3: "1.6",
    ls4: "1.8",
    ls5: "2.0"
  },
  order: {
    o0: "0",
    o1: "1",
    o2: "2",
    o3: "3",
    o4: "4",
    o5: "5"
  },
  borderWidth: {
    b1: "1px",
    b2: "2px"
  }
});

export { scales };
