import{bake as v,mediaQueryMap as P}from"@sonos-inc/www-bakery";import{useWindowDimensions as h}from"@sonos-inc/www-hooks";import{logger as E}from"@sonos-inc/www-server/utils/logger";import S from"next/script";import n from"react";import{ConditionalFragment as O}from"../ConditionalFragment/ConditionalFragment.mjs";import{SonosVideo as I}from"../SonosVideo/SonosVideo.mjs";import{mediaVideoContainer as T,mediaVideo as c}from"./MediaVideo.css.vanilla.mjs";var _=Object.defineProperty,j=Object.defineProperties,w=Object.getOwnPropertyDescriptors,f=Object.getOwnPropertySymbols,V=Object.prototype.hasOwnProperty,M=Object.prototype.propertyIsEnumerable,g=(o,e,r)=>e in o?_(o,e,{enumerable:!0,configurable:!0,writable:!0,value:r}):o[e]=r,m=(o,e)=>{for(var r in e||(e={}))V.call(e,r)&&g(o,r,e[r]);if(f)for(var r of f(e))M.call(e,r)&&g(o,r,e[r]);return o},C=(o,e)=>j(o,w(e));const R=({_key:o,responsiveVideos:e,preload:r=!1,className:k,isSplashPage:b=!1})=>{const l=`_${o}`,p=n.useRef(null),$=n.useCallback(()=>{var i,s;const u=Array.from((s=(i=p.current)==null?void 0:i.querySelectorAll("video"))!=null?s:[]);u.forEach(a=>{!a.paused&&a.currentTime>0&&!a.ended&&(a.pause(),a.currentTime=0)});const[t]=u.filter(a=>{var y;return!!((y=a.parentElement)==null?void 0:y.offsetParent)});if(!t)return;const d=t.dataset.isBackground==="true",B=t.paused&&t.readyState>2;t.autoplay=d,t.playsInline=d,t.muted=d,t.loop=d,t.preload=d?"none":"metadata",B&&d&&t.play().catch(a=>E.error(`Fail to play the video with error: ${a}`))},[p]);return h($),n.createElement("div",m({"data-testid":"media-video",id:l,ref:p},v(T,k)),n.createElement("style",{dangerouslySetInnerHTML:{__html:`
              #${l} > .${c}.desktop { display: none; }
              @media ${P.desktop} {
                #${l} > .${c}.mobile { display: none; }
                #${l} > .${c}.desktop { display: block; }
              }
            `}}),e.map(({video:i,mediaQuery:s})=>{var u;return n.createElement(I,C(m(m({key:i._key||i.asset.assetId,mediaQuery:s,preload:r},i),v(c,s)),{"data-is-background":`${(u=i.enableBackgroundVideo&&!i.enableControls)!=null?u:!0}`,isSplashPage:b}))}),n.createElement(O,{shouldRender:r},n.createElement(S,{id:"media-video-script",dangerouslySetInnerHTML:{__html:`
            (function () {
              const container = document.getElementById('${l}')
              const videos = [...container.querySelectorAll("video")]
              const [video] = videos.filter(
                (el) => el.parentElement.offsetParent !== null
              );
              if (!video) return
              const isBackground = video.dataset.isBackground === "true"
              const isReadyToPlay = video.paused && video.readyState > 2

              video.autoplay = isBackground;
              video.playsInline = isBackground;
              video.muted = isBackground;
              video.loop = isBackground;
              video.preload = isBackground ? 'none' : 'metadata';

              if (isReadyToPlay && isBackground) {
                video.play()
              }
            })()
          `}})))};export{R as MediaVideo};
