import { dropNumericKeyEntries, mapValues } from '../utilities/mapping.mjs';

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var ResponsiveBreakpoints = /* @__PURE__ */ ((ResponsiveBreakpoints2) => {
  ResponsiveBreakpoints2[ResponsiveBreakpoints2["mobile"] = 0] = "mobile";
  ResponsiveBreakpoints2[ResponsiveBreakpoints2["mobileXL"] = 375] = "mobileXL";
  ResponsiveBreakpoints2[ResponsiveBreakpoints2["tablet"] = 768] = "tablet";
  ResponsiveBreakpoints2[ResponsiveBreakpoints2["desktop"] = 1024] = "desktop";
  ResponsiveBreakpoints2[ResponsiveBreakpoints2["desktopXL"] = 1440] = "desktopXL";
  return ResponsiveBreakpoints2;
})(ResponsiveBreakpoints || {});
const responsiveBreakpoints = dropNumericKeyEntries(ResponsiveBreakpoints);
const responsiveQueries = mapValues(responsiveBreakpoints, (name, pixels) => `screen and (min-width:${pixels / 16}em)`);
const mediaQueries = [
  "mobile",
  "tablet",
  "desktop",
  "desktopXL"
];
const allResponsiveBreakpoints = [
  "all",
  ...mediaQueries
];
const mediaQueryMap = {
  all: "(min-width: 0px)",
  mobile: responsiveQueries.mobile,
  tablet: responsiveQueries.tablet,
  desktop: responsiveQueries.desktop,
  desktopXL: responsiveQueries.desktopXL
};
function getMobileFirstOrderedBreakpoints() {
  const { all, mobile, tablet, desktop, desktopXL } = mediaQueryMap;
  return [all, mobile, tablet, desktop, desktopXL];
}
const mobileFirstOrderedBreakpoints = getMobileFirstOrderedBreakpoints();
function responsive({
  mobile,
  tablet,
  desktop,
  desktopXL
}) {
  if (tablet || desktop || desktopXL) {
    return __spreadProps(__spreadValues({}, mobile), {
      "@media": __spreadValues(__spreadValues(__spreadValues({}, tablet && { [responsiveQueries.tablet]: tablet }), desktop && { [responsiveQueries.desktop]: desktop }), desktopXL && { [responsiveQueries.desktopXL]: desktopXL })
    });
  } else {
    return mobile;
  }
}
function mapSanityBreakpointValues(breakpoints, breakpoint, propertyValue, defaultValue) {
  return Object.fromEntries(breakpoints.map((bp) => [
    bp,
    breakpoint === bp ? propertyValue : defaultValue
  ]));
}
const breakpointMap = {
  mobile: "mobile",
  tablet: "mobile",
  desktop: "desktop",
  desktopXL: "desktop"
};
function mapMultipleSanityBreakpointValues(allBreakpoints, includeBreakpoints, propertyValue, defaultValue) {
  return Object.fromEntries(allBreakpoints.map((bp) => [
    bp,
    includeBreakpoints.includes(bp) || includeBreakpoints.includes(breakpointMap[bp]) ? propertyValue : defaultValue
  ]));
}
function parseResponsiveSettings(responsiveSettings) {
  return Object.fromEntries(allResponsiveBreakpoints.map((targetScreenSize) => [
    targetScreenSize,
    responsiveSettings == null ? void 0 : responsiveSettings.find((rs) => rs.mediaQuery === targetScreenSize)
  ]));
}

export { allResponsiveBreakpoints, mapMultipleSanityBreakpointValues, mapSanityBreakpointValues, mediaQueries, mediaQueryMap, mobileFirstOrderedBreakpoints, parseResponsiveSettings, responsive, responsiveBreakpoints, responsiveQueries };
