import pino from 'pino';

var _a;
const loggingLevel = (_a = process.env.LOGGING_LEVEL) != null ? _a : "error";
const logger = pino({
  name: "www",
  level: loggingLevel
});

export { logger };
