const productColors = {
  "lunar-white": "#cdced0",
  "shadow-black": "#454749",
  yellow: "#DED298",
  grey: "#BCBAB6",
  black: "#000000",
  white: "#FFFFFF",
  walnut: "#947051",
  sunset: "#D07464",
  wave: "#859CAB",
  olive: "#7C8476"
};

export { productColors };
