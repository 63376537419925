import { getVarName } from '@vanilla-extract/private';
import classNames from 'classnames';
import { whisk } from './whisk.mjs';

function bake(...styles) {
  const className = classNames(...styles.flatMap((x) => {
    if (!x)
      return [];
    else if (typeof x === "object")
      return whisk(x);
    return x;
  }));
  return className ? { className } : {};
}
function broil(cssVar, value) {
  return {
    [getVarName(cssVar)]: value
  };
}

export { bake, broil };
