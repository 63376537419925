var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"5124c0a53c2d221cc0d15a92fd67632584151df6"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://8cca376e7f8f4180a4e1ba2bbc3c2898@o167642.ingest.sentry.io/4504685144571905',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.2,
  environment: process.env.NEXT_PUBLIC_SONOS_ENV || 'development',
  enabled: false,
  // enabled: ['prod', 'stage', 'test'].includes(
  //   process.env.NEXT_PUBLIC_SONOS_ENV || ''
  // ),
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
