import { dropNumericKeyEntries, mapEntries, mapValues } from '../utilities/mapping.mjs';
import { responsiveBreakpoints } from './dishes.mjs';

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
function makeGrid({
  base,
  unitType,
  maxSize
}) {
  const gridBase = dropNumericKeyEntries(base);
  const divisions = Object.keys(gridBase).length - 1;
  const startingEntries = mapEntries(gridBase, (e) => e.filter(([name, value]) => value !== divisions));
  const endingEntries = mapEntries(gridBase, (e) => e.filter(([name, value]) => value !== 0));
  const getColumnSize = (colspan) => colspan * maxSize / divisions;
  const regularWidths = mapValues(gridBase, (alias, span) => getColumnSize(span));
  const negativeWidths = mapEntries(endingEntries, (entry) => entry.map(([name, span]) => [`-${name}`, -getColumnSize(span)]));
  const halfWidths = mapEntries(startingEntries, (entry) => entry.map(([name, span]) => [`${name}p5`, getColumnSize(span + 0.5)]));
  const negativeHalfWidths = mapEntries(halfWidths, (entry) => entry.map(([name, size]) => [`-${name}`, -size]));
  const combined = __spreadValues(__spreadValues(__spreadValues(__spreadValues({}, regularWidths), halfWidths), negativeWidths), negativeHalfWidths);
  return mapValues(combined, (name, size) => `${size}${unitType}`);
}
var xGridBase = /* @__PURE__ */ ((xGridBase2) => {
  xGridBase2[xGridBase2["x0"] = 0] = "x0";
  xGridBase2[xGridBase2["x1"] = 1] = "x1";
  xGridBase2[xGridBase2["x2"] = 2] = "x2";
  xGridBase2[xGridBase2["x3"] = 3] = "x3";
  xGridBase2[xGridBase2["x4"] = 4] = "x4";
  xGridBase2[xGridBase2["x5"] = 5] = "x5";
  xGridBase2[xGridBase2["x6"] = 6] = "x6";
  xGridBase2[xGridBase2["x7"] = 7] = "x7";
  xGridBase2[xGridBase2["x8"] = 8] = "x8";
  xGridBase2[xGridBase2["x9"] = 9] = "x9";
  xGridBase2[xGridBase2["x10"] = 10] = "x10";
  xGridBase2[xGridBase2["x11"] = 11] = "x11";
  xGridBase2[xGridBase2["x12"] = 12] = "x12";
  xGridBase2[xGridBase2["x13"] = 13] = "x13";
  xGridBase2[xGridBase2["x14"] = 14] = "x14";
  xGridBase2[xGridBase2["x15"] = 15] = "x15";
  xGridBase2[xGridBase2["x16"] = 16] = "x16";
  xGridBase2[xGridBase2["x17"] = 17] = "x17";
  xGridBase2[xGridBase2["x18"] = 18] = "x18";
  xGridBase2[xGridBase2["x19"] = 19] = "x19";
  xGridBase2[xGridBase2["x20"] = 20] = "x20";
  xGridBase2[xGridBase2["x21"] = 21] = "x21";
  xGridBase2[xGridBase2["x22"] = 22] = "x22";
  xGridBase2[xGridBase2["x23"] = 23] = "x23";
  xGridBase2[xGridBase2["x24"] = 24] = "x24";
  xGridBase2[xGridBase2["x25"] = 25] = "x25";
  xGridBase2[xGridBase2["x26"] = 26] = "x26";
  xGridBase2[xGridBase2["x27"] = 27] = "x27";
  return xGridBase2;
})(xGridBase || {});
const xGrid = makeGrid({
  base: xGridBase,
  unitType: "vw",
  maxSize: 100
});
const xGridDesktopXL = makeGrid({
  base: xGridBase,
  unitType: "px",
  maxSize: responsiveBreakpoints.desktopXL
});
var sGridBase = /* @__PURE__ */ ((sGridBase2) => {
  sGridBase2[sGridBase2["s0"] = 0] = "s0";
  sGridBase2[sGridBase2["s1"] = 1] = "s1";
  sGridBase2[sGridBase2["s2"] = 2] = "s2";
  sGridBase2[sGridBase2["s3"] = 3] = "s3";
  sGridBase2[sGridBase2["s4"] = 4] = "s4";
  sGridBase2[sGridBase2["s5"] = 5] = "s5";
  sGridBase2[sGridBase2["s6"] = 6] = "s6";
  sGridBase2[sGridBase2["s7"] = 7] = "s7";
  sGridBase2[sGridBase2["s8"] = 8] = "s8";
  sGridBase2[sGridBase2["s9"] = 9] = "s9";
  sGridBase2[sGridBase2["s10"] = 10] = "s10";
  sGridBase2[sGridBase2["s11"] = 11] = "s11";
  sGridBase2[sGridBase2["s12"] = 12] = "s12";
  sGridBase2[sGridBase2["s13"] = 13] = "s13";
  sGridBase2[sGridBase2["s14"] = 14] = "s14";
  sGridBase2[sGridBase2["s15"] = 15] = "s15";
  sGridBase2[sGridBase2["s16"] = 16] = "s16";
  sGridBase2[sGridBase2["s17"] = 17] = "s17";
  sGridBase2[sGridBase2["s18"] = 18] = "s18";
  sGridBase2[sGridBase2["s19"] = 19] = "s19";
  sGridBase2[sGridBase2["s20"] = 20] = "s20";
  sGridBase2[sGridBase2["s21"] = 21] = "s21";
  sGridBase2[sGridBase2["s22"] = 22] = "s22";
  sGridBase2[sGridBase2["s23"] = 23] = "s23";
  sGridBase2[sGridBase2["s24"] = 24] = "s24";
  sGridBase2[sGridBase2["s25"] = 25] = "s25";
  sGridBase2[sGridBase2["s26"] = 26] = "s26";
  sGridBase2[sGridBase2["s27"] = 27] = "s27";
  sGridBase2[sGridBase2["s28"] = 28] = "s28";
  sGridBase2[sGridBase2["s29"] = 29] = "s29";
  sGridBase2[sGridBase2["s30"] = 30] = "s30";
  sGridBase2[sGridBase2["s31"] = 31] = "s31";
  sGridBase2[sGridBase2["s32"] = 32] = "s32";
  sGridBase2[sGridBase2["s33"] = 33] = "s33";
  sGridBase2[sGridBase2["s34"] = 34] = "s34";
  sGridBase2[sGridBase2["s35"] = 35] = "s35";
  sGridBase2[sGridBase2["s36"] = 36] = "s36";
  sGridBase2[sGridBase2["s37"] = 37] = "s37";
  sGridBase2[sGridBase2["s38"] = 38] = "s38";
  sGridBase2[sGridBase2["s39"] = 39] = "s39";
  sGridBase2[sGridBase2["s40"] = 40] = "s40";
  sGridBase2[sGridBase2["s41"] = 41] = "s41";
  sGridBase2[sGridBase2["s42"] = 42] = "s42";
  sGridBase2[sGridBase2["s43"] = 43] = "s43";
  sGridBase2[sGridBase2["s44"] = 44] = "s44";
  sGridBase2[sGridBase2["s45"] = 45] = "s45";
  sGridBase2[sGridBase2["s46"] = 46] = "s46";
  sGridBase2[sGridBase2["s47"] = 47] = "s47";
  sGridBase2[sGridBase2["s48"] = 48] = "s48";
  sGridBase2[sGridBase2["s49"] = 49] = "s49";
  sGridBase2[sGridBase2["s50"] = 50] = "s50";
  sGridBase2[sGridBase2["s51"] = 51] = "s51";
  sGridBase2[sGridBase2["s52"] = 52] = "s52";
  sGridBase2[sGridBase2["s53"] = 53] = "s53";
  sGridBase2[sGridBase2["s54"] = 54] = "s54";
  sGridBase2[sGridBase2["s55"] = 55] = "s55";
  sGridBase2[sGridBase2["s56"] = 56] = "s56";
  sGridBase2[sGridBase2["s57"] = 57] = "s57";
  sGridBase2[sGridBase2["s58"] = 58] = "s58";
  sGridBase2[sGridBase2["s59"] = 59] = "s59";
  sGridBase2[sGridBase2["s60"] = 60] = "s60";
  sGridBase2[sGridBase2["s61"] = 61] = "s61";
  sGridBase2[sGridBase2["s62"] = 62] = "s62";
  sGridBase2[sGridBase2["s63"] = 63] = "s63";
  sGridBase2[sGridBase2["s64"] = 64] = "s64";
  sGridBase2[sGridBase2["s65"] = 65] = "s65";
  sGridBase2[sGridBase2["s66"] = 66] = "s66";
  sGridBase2[sGridBase2["s67"] = 67] = "s67";
  sGridBase2[sGridBase2["s68"] = 68] = "s68";
  sGridBase2[sGridBase2["s69"] = 69] = "s69";
  sGridBase2[sGridBase2["s70"] = 70] = "s70";
  sGridBase2[sGridBase2["s71"] = 71] = "s71";
  sGridBase2[sGridBase2["s72"] = 72] = "s72";
  sGridBase2[sGridBase2["s73"] = 73] = "s73";
  sGridBase2[sGridBase2["s74"] = 74] = "s74";
  sGridBase2[sGridBase2["s75"] = 75] = "s75";
  sGridBase2[sGridBase2["s76"] = 76] = "s76";
  sGridBase2[sGridBase2["s77"] = 77] = "s77";
  sGridBase2[sGridBase2["s78"] = 78] = "s78";
  sGridBase2[sGridBase2["s79"] = 79] = "s79";
  sGridBase2[sGridBase2["s80"] = 80] = "s80";
  sGridBase2[sGridBase2["s81"] = 81] = "s81";
  sGridBase2[sGridBase2["s82"] = 82] = "s82";
  sGridBase2[sGridBase2["s83"] = 83] = "s83";
  sGridBase2[sGridBase2["s84"] = 84] = "s84";
  sGridBase2[sGridBase2["s85"] = 85] = "s85";
  sGridBase2[sGridBase2["s86"] = 86] = "s86";
  sGridBase2[sGridBase2["s87"] = 87] = "s87";
  sGridBase2[sGridBase2["s88"] = 88] = "s88";
  sGridBase2[sGridBase2["s89"] = 89] = "s89";
  sGridBase2[sGridBase2["s90"] = 90] = "s90";
  sGridBase2[sGridBase2["s91"] = 91] = "s91";
  sGridBase2[sGridBase2["s92"] = 92] = "s92";
  sGridBase2[sGridBase2["s93"] = 93] = "s93";
  sGridBase2[sGridBase2["s94"] = 94] = "s94";
  sGridBase2[sGridBase2["s95"] = 95] = "s95";
  sGridBase2[sGridBase2["s96"] = 96] = "s96";
  sGridBase2[sGridBase2["s97"] = 97] = "s97";
  sGridBase2[sGridBase2["s98"] = 98] = "s98";
  sGridBase2[sGridBase2["s99"] = 99] = "s99";
  sGridBase2[sGridBase2["s100"] = 100] = "s100";
  sGridBase2[sGridBase2["s101"] = 101] = "s101";
  sGridBase2[sGridBase2["s102"] = 102] = "s102";
  sGridBase2[sGridBase2["s103"] = 103] = "s103";
  sGridBase2[sGridBase2["s104"] = 104] = "s104";
  sGridBase2[sGridBase2["s105"] = 105] = "s105";
  sGridBase2[sGridBase2["s106"] = 106] = "s106";
  sGridBase2[sGridBase2["s107"] = 107] = "s107";
  sGridBase2[sGridBase2["s108"] = 108] = "s108";
  sGridBase2[sGridBase2["s109"] = 109] = "s109";
  sGridBase2[sGridBase2["s110"] = 110] = "s110";
  sGridBase2[sGridBase2["s111"] = 111] = "s111";
  sGridBase2[sGridBase2["s112"] = 112] = "s112";
  sGridBase2[sGridBase2["s113"] = 113] = "s113";
  return sGridBase2;
})(sGridBase || {});
var yGridBase = /* @__PURE__ */ ((yGridBase2) => {
  yGridBase2[yGridBase2["y0"] = 0] = "y0";
  yGridBase2[yGridBase2["y1"] = 1] = "y1";
  yGridBase2[yGridBase2["y2"] = 2] = "y2";
  yGridBase2[yGridBase2["y3"] = 3] = "y3";
  yGridBase2[yGridBase2["y4"] = 4] = "y4";
  yGridBase2[yGridBase2["y5"] = 5] = "y5";
  yGridBase2[yGridBase2["y6"] = 6] = "y6";
  yGridBase2[yGridBase2["y7"] = 7] = "y7";
  yGridBase2[yGridBase2["y8"] = 8] = "y8";
  yGridBase2[yGridBase2["y9"] = 9] = "y9";
  yGridBase2[yGridBase2["y10"] = 10] = "y10";
  yGridBase2[yGridBase2["y11"] = 11] = "y11";
  yGridBase2[yGridBase2["y12"] = 12] = "y12";
  return yGridBase2;
})(yGridBase || {});
const yGrid = makeGrid({
  base: yGridBase,
  unitType: "px",
  maxSize: 1350
});
const sGrid = makeGrid({
  base: sGridBase,
  unitType: "px",
  maxSize: 904
});
const grids = {
  baseGrid: {
    max: "100%"
  },
  sGrid,
  xGrid: __spreadProps(__spreadValues({}, xGrid), {
    xMax: "100vw"
  }),
  yGrid: __spreadProps(__spreadValues({}, yGrid), {
    yMax: "100vh"
  })
};
const getUnitSize = (unit, value) => `${value < 0 ? "-" : ""}${unit}${Math.abs(value)}`;
const getXGridSize = (colspan) => `x${Math.floor(colspan)}${colspan % 1 === 0.5 && colspan < 27 ? "p5" : ""}`;
const getYGridSize = (value) => `y${value}`;
const getSGridSize = (value) => getUnitSize("s", value);
const getXGridSizeWithFitContent = (colspan) => colspan === 0 ? "fitContent" : getXGridSize(colspan);

export { getSGridSize, getUnitSize, getXGridSize, getXGridSizeWithFitContent, getYGridSize, grids, sGrid, sGridBase, xGrid, xGridBase, xGridDesktopXL, yGrid };
