import e from"react";const c=process.env.NEXT_PUBLIC_ONETRUST_URL,p=process.env.NEXT_PUBLIC_ONETRUST_DATA_DOMAIN,l=`function OptanonWrapper() {
  window.dataLayer.push({
    event: 'OneTrustGroupsUpdated',
  })
  function getCookie(name) {
    var value = '; ' + document.cookie
    var parts = value.split('; ' + name + '=')
    if (parts.length == 2) return parts.pop().split(';').shift()
  }
  if (!getCookie('OptanonAlertBoxClosed')) {
    var buttons = document.getElementsByClassName(
      'optanon-white-button-middle'
    )
    for (var i = 0; i < buttons.length; i++) {
      var banner = document.getElementById(
        'center-tile-banner-popup'
      )
      buttons[i].addEventListener('click', function close() {
        banner.style.transition = '0.8s'
        banner.style.opacity = 0
      })
    }
    if (OneTrust && OneTrust.IsAlertBoxClosed() === false) {
      document.body.classList.add("has-cookie-banner");
    } else {
        document.body.classList.remove("has-cookie-banner");
    }
  }
}`,a=process.env.NEXT_PUBLIC_GTM_ID,d=`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;j.addEventListener('load',function(){d.dispatchEvent(new CustomEvent('gtmloaded', { bubbles: true }));});f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${a}');`,m=process.env.NEXT_PUBLIC_GOOGLE_VERIFICATION,s=process.env.NEXT_PUBLIC_OPTIMIZELY_URL,r=process.env.NEXT_PUBLIC_XGEN_URL,i=process.env.NEXT_PUBLIC_CJ_URL,u=t=>{var n;const o={event:"pageview",page:t};return window.google_tag_manager&&((n=window.dataLayer)==null||n.push(o)),o},g=()=>{const[t,n]=e.useState(!1);return e.useEffect(()=>{typeof window<"u"&&window.OptanonActiveGroups!==void 0&&window.OptanonActiveGroups&&window.OptanonActiveGroups.indexOf("C0004")!==-1&&n(!0)},[]),e.createElement(e.Fragment,null,s&&e.createElement("script",{type:t?"text/javascript":"text/plain",className:"optanon-category-C0002-C0004",src:s,defer:!0}),r&&e.createElement("script",{type:t?"text/javascript":"text/plain",className:"optanon-category-C0002-C0004",src:r,id:"xgentag",defer:!0}),i&&e.createElement("script",{type:t?"text/javascript":"text/plain",className:"optanon-category-C0002-C0004",src:i,id:"cjapitag",async:!0}))},v=()=>e.createElement("noscript",null,e.createElement("iframe",{src:`https://www.googletagmanager.com/ns.html?id=${a}`,height:"0",width:"0",style:{display:"none",visibility:"hidden"},title:"Google Tag Manager"}));export{g as VendorScripts,v as VendorScriptsBody,m as gVerificationId,a as gtmId,u as gtmPageView,d as gtmScript,p as oneTrustDataDomain,l as oneTrustScript,c as oneTrustUrl};
