import { isEmpty } from '@sonos-inc/www-lib';
import classNames from 'classnames';
import { css } from 'goober';
import { dropEmptyValueEntries, mapValues } from '../utilities/mapping.mjs';
import { responsiveBreakpoints } from './dishes.mjs';
import { sGrid } from './dividers.mjs';
import { palette } from './icing/recipe.css.vanilla.mjs';
import { autoSize, space, sGridLightNegatives, xGridPan, fontSize, fontWeight, lineHeight, order, yGridPan, borderWidth } from './pans.css.vanilla.mjs';

var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
const gridSize = __spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues({}, sGrid), autoSize), space), sGridLightNegatives), xGridPan), yGridPan);
const allTokens = __spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues({}, gridSize), fontSize), fontWeight), lineHeight), palette), order), borderWidth);
const resolveTokenValue = (value) => {
  if (value in allTokens) {
    return allTokens[value];
  }
  return value;
};
const grabCSSAttribute = (design, attr, fallbackAttr) => {
  const value = resolveTokenValue(design[attr] || design[fallbackAttr]);
  if (isEmpty(value))
    return {};
  return { [attr]: value };
};
const distillDirectionalAttribute = (attr, design) => {
  const attrTop = `${attr}Top`;
  const attrBottom = `${attr}Bottom`;
  const attrLeft = `${attr}Left`;
  const attrRight = `${attr}Right`;
  const attrX = `${attr}X`;
  const attrY = `${attr}Y`;
  const result = __spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues({}, design), grabCSSAttribute(design, attrTop, attrY)), grabCSSAttribute(design, attrBottom, attrY)), grabCSSAttribute(design, attrLeft, attrX)), grabCSSAttribute(design, attrRight, attrX));
  delete result[attrX];
  delete result[attrY];
  return result;
};
const grabStyleGroupAttributes = (styleGroup, design) => dropEmptyValueEntries(mapValues(design, (name, value) => {
  switch (typeof value) {
    case "string":
      return styleGroup === "all" ? resolveTokenValue(value) : void 0;
    case "object":
      return resolveTokenValue(value[styleGroup]);
    default:
      return void 0;
  }
}));
const distillStyleGroupDirectionalAttributes = (styleGroup, design, directionalAttributes = []) => directionalAttributes.reduce((acc, attr) => distillDirectionalAttribute(attr, acc), grabStyleGroupAttributes(styleGroup, design));
const distillStyles = (design, exclude = []) => dropEmptyValueEntries(mapValues(__spreadValues({ all: 0, hover: 0, active: 0 }, responsiveBreakpoints), (styleGroup, minWidth) => {
  const attrs = distillStyleGroupDirectionalAttributes(styleGroup, design, ["margin", "padding", "border"]);
  if (isEmpty(attrs) || exclude.includes(styleGroup)) {
    return null;
  } else if (["hover", "active"].includes(styleGroup)) {
    return { [`&:${styleGroup}`]: attrs };
  } else if (minWidth !== 0) {
    return { [`@media (min-width:${minWidth}px)`]: attrs };
  }
  return attrs;
}));
function mergeGooberStyles(styles) {
  let result = {};
  styles.forEach((style) => Object.keys(style).forEach((key) => {
    const styleValue = style[key];
    if (typeof styleValue === "object") {
      result[key] = __spreadValues(__spreadValues({}, result[key]), styleValue);
    } else {
      result[key] = styleValue;
    }
  }));
  return css({ [`body &`]: result });
}
const whisk = (design) => {
  const gooberObj = distillStyles(design);
  const gooberClass = mergeGooberStyles([...Object.values(gooberObj)]);
  return classNames(gooberClass);
};

export { whisk };
